import { Button } from "@mui/base"
import { B4Modal, B4ModalPosition } from "./modal"
import { B4Text } from "./text"
import { MdClose, MdDone } from "react-icons/md"
import { B4IconButton } from "./button"
import clsx from "clsx"
import { useContext } from "react"
import { B4BackgroundContext, B4BackgroundType } from "./utils"
import { B4PoweredBy } from "../../components/common/standard"

export const B4NavItem = ({children, onClick, active = false, borderBig = false}) => (
  <div className="bg-white w-full">
    <Button disabled={active} className={clsx(
        'border-b p-3 text-left flex justify-between items-center w-full', {
        'active:bg-[#E3E4EE]' : !active,
        'font-medium': active,
        'border-b': !borderBig,
        'border-b-2': borderBig,
      })} onClick={onClick}>
      <B4Text>{children}</B4Text>
      {active && <div className="text-xl"><MdDone /></div>}
    </Button>
  </div>
)
  
export const B4Navigation = ({children, open, onClose, header = null}) => {
  const { bgType } = useContext(B4BackgroundContext)
  return (
    <B4Modal open={open} position={B4ModalPosition.TOP} onClose={onClose} full padding={false}>
      <div className={clsx('bg-white shadow', {'shadow-b4-primary': bgType === B4BackgroundType.DARK})}>
        <div className="w-full flex justify-center">
          <div className="flex justify-between p-3 items-center border-b max-w-full w-b4-max">
            <div>{header}</div>
            <B4IconButton big={true} onClick={onClose}><MdClose/></B4IconButton>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-b4-max max-w-full">
            {children}
          </div>
        </div>
      </div>
      <B4PoweredBy className='bg-white py-b4-std' ruler={false} />
    </B4Modal>
  )
}