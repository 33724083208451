import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDebounceEffect } from 'ahooks';

const options = {
  wait: 300,
};

const FormikPersist: React.FC<{
  name: string;
  initialSubmitForm: boolean;
}> = ({ name, initialSubmitForm }) => {
  const { setValues, values, submitForm } = useFormikContext();

  const key = `formik-${name}`;

  useEffect(() => {
    if (sessionStorage.getItem(key)) {
      const persistValues = JSON.parse(sessionStorage.getItem(key));
      const language = localStorage.getItem('lang')
        ? [localStorage.getItem('lang')]
        : [];
      setValues({
        ...persistValues,
        language,
      });
      initialSubmitForm && submitForm();
    }
  }, [initialSubmitForm, key, setValues, submitForm]);

  useDebounceEffect(
    () => {
      sessionStorage.setItem(key, JSON.stringify(values));
    },
    [values, setValues],
    options,
  );

  return null;
};

export default FormikPersist;
