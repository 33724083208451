import { gql } from '@apollo/client';

export const SEND_FEEDBACK_MUTATION = gql`
  mutation($feedback: String!) {
    sendFeedback(feedback: $feedback) {
      id
      feedback
    }
  }
`;
