import { useQuery, gql } from '@apollo/client';

export const QUESTION_STATS_QUERY = gql`
  query ($conversation_id: ID!) {
    GetQuestionStats(id: $conversation_id) {
      choices
      choices_seed
      choices_new
      choices_duplicates
      choices_avg
      choices_contributors
      prompts_participants
      prompts_done
      prompts_done_avg
      skippers
      regular_skips
      both_good_skips
      skips_avg
      voters
      votes
      votes_avg
      total_participants
    }
  }
`;

const useQuestionStats = (options = {}) => {
  return useQuery(QUESTION_STATS_QUERY, options);
};

export default useQuestionStats;
