import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { CURRENT_TENANT_QUERY } from '../queries/useTenant';

const UPLOAD_TENANT_IMAGE = gql`
  mutation($id: ID!, $image: Upload!) {
    uploadTenantImage(id: $id, file: $image)
  }
`;

const useUploadTenantImage = () => {
  return useMutation(UPLOAD_TENANT_IMAGE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CURRENT_TENANT_QUERY,
      },
    ],
  });
};

export default useUploadTenantImage;
