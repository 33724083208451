import { useAuth, useMe } from '../../hooks';
import { IconButton } from '@mui/material';
import { Build } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const AdminButton = () => {
  const { loading, data: authData } = useAuth()
  const { data: meData } = useMe()
  const navigate = useNavigate()

  if (loading || !authData.isLoggedIn) return null

  if (meData?.me.type !== 'Admin') return null

  return (
    <IconButton onClick={() => navigate('/admin')}>
      <Build/>
    </IconButton>
  );
};




export default AdminButton;