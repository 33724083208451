import { createTheme } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#2F327F',
    },
    secondary: {
      contrastText: "#fff",
      main: '#ED7D31',
    },
    success: { // misused for gold
      main: '#c3a400',
    }
  },
  zIndex: {
    ...zIndex,
    appBar: 40,
  },
  components: {
    MuiAppBar: {
      defaultProps: { position: 'static', color: 'inherit' }
    },
    MuiCard: {
      defaultProps: { elevation: 0},
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          backgroundColor: 'rgba(255, 255, 255, 0.46)',
          backgroundImage:
            'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0.25rem 0.75rem',
          '&:first-of-type': {
            paddingTop: '0.75rem',
          },
          '&:last-child': {
            paddingBottom: '0.75rem',
          },
          color: '#A4A4A4',
          '&:empty': { display: 'none' },
        },
      }
    },
    MuiCardActionArea: {
      defaultProps: { disableTouchRipple: true }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'body1',
          style: {
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        },
        subheaderTypographyProps: {
          variant: 'subtitle2',
          style: { fontSize: '0.75rem' },
        }
      },
      styleOverrides: {
        root: {
          padding: '0.25rem 0.75rem',
          '&:first-of-type': {
            paddingTop: '0.75rem',
          },
          '&:last-child': {
            paddingBottom: '0.75rem',
          },
        },
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          minHeight: '0.75rem',
          fontSize: '0.75rem',
          padding: '0.25rem 0',
          minWidth: '6rem',
          transition: 'color 0.5s linear',
          '@media (min-width: 600px)': {
            minWidth: '6rem',
          },
          '&$selected': {
            color: 'white',
          },
        },
      }
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            zIndex: -1,
            height: '100%',
            borderRadius: '1rem',
          },
        },
      },
      styleOverrides: {
        root: {
          minHeight: '0.75rem',
          height: '100%',
        },
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xs'
      } },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      } },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 4000,
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
          maxWidth: '95vw',
          fontSize: '0.8rem',
        },
        arrow: {color: 'black'},
        touch: {fontSize: '0.8rem'},
        tooltipPlacementTop: {margin: '1.5rem 0 0.5rem'},
        tooltipPlacementBottom: {margin: '0.5rem 0 1.5rem'},
      }
    },
    MuiFormControlLabel: {
      defaultProps: {
        labelPlacement: 'start'
      },
      styleOverrides: {
        root: {
          justifyContent: 'space-between', marginLeft: 0, marginRight: 0
        },
        labelPlacementStart: {
          justifyContent: 'space-between',
          marginLeft: 0,
          marginRight: 0,
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
            fontSize: '0.75rem',
            fontWeight: 500,
          "&.Mui-selected": {
            fontSize: '0.75rem',
            fontWeight: 500,
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: '0.25rem' }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          minWidth: '4rem',
        },
        label: {
          // textTransform: 'capitalize',
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0.5rem 0.75rem',
          '&:first-of-type': {
            paddingTop: '0.75rem',
          },
          '&:last-child': {
            paddingBottom: '0.75rem',
          },
        },
      }
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {minHeight: '4rem'}
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: '#A4A4A4', padding: '0.75rem' }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          overflow: 'visible'
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: 'white'
        }
      }
    }
  },
});
