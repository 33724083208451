import { LinearProgress } from "@mui/material";
import { useContext } from "react";
import { B4LoadingContext } from "../../utils/b4Context";

const ActivityIndicator = () => {
  const ctContext = useContext(B4LoadingContext);
  return ctContext.loading && <LinearProgress style={{marginTop: '-0.125rem'}} className='h-0.5' />
}

export default ActivityIndicator
