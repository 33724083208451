import React, { ReactNode } from 'react';

import Header from './header';

type LayoutProps = {
  children?: ReactNode;
};

const Layout = ({ children }: LayoutProps) => (
  <div className='flex flex-col min-h-full pb-safe'>
    <Header />
    <div className='flex flex-col flex-1 h-full w-full max-w-md mx-auto'>
      {children}
    </div>
  </div>
);

export default Layout;
