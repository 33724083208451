/** @jsxImportSource @emotion/react */
import clsx from "clsx"
import { ReactNode, memo } from "react"
import { ctLinkifyText } from "../../utils/ctLinkify"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"

export enum B4TextColor {
  BLUE,
  BLUE_TITLE,
  WHITE,
  GREEN,
  RED,
  DISABLED
}

export interface B4TextProps {
  children?: ReactNode,
  html?: string
  color?: B4TextColor,
  className?: string,
  innerClassName?: string,
  label?: string,
  innerStyle?: React.CSSProperties
}


const B4TextBase = ({children = null, className, innerClassName = null, color = B4TextColor.BLUE, label = null, html = null, innerStyle = null}) => (
  <div className={clsx({
    'text-b4-primary': color === B4TextColor.BLUE,
    'text-b4-title': color === B4TextColor.BLUE_TITLE,
    'text-white': color === B4TextColor.WHITE,
    'text-b4-secondary': color === B4TextColor.GREEN,
    'text-red-500': color === B4TextColor.RED,
    'text-gray-600': color === B4TextColor.DISABLED,
  }, className)}>
    {label && <div className="pb-1 text-sm">{label}</div>}
    {html ? <div className={innerClassName} style={innerStyle} css={css`
      p + p {
        margin-top: 0.25rem;
      },
      p {
        min-height: 1rem;
      }
    `}  dangerouslySetInnerHTML={{ __html: ctLinkifyText(html) }} /> : <div className={innerClassName}>{children}</div> }
  </div>
)

const B4TextBaseTitle = ({className, color = B4TextColor.BLUE_TITLE, ...props}: B4TextProps) => (
  <B4TextBase className={className} color={color} {...props} />
)

export const B4TextBig = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-2xl', className)} {...props} />
))

export const B4Text = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-base', className)} {...props} />
))

export const B4TextEmp = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-base font-semibold', className)} {...props} />
))

export const B4TextSubmedium = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-lg', className)} {...props} />
))

export const B4TextMedium = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-xl', className)} {...props} />
))

export const B4TextSmall = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-sm', className)} {...props} />
))

export const B4TextTiny = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-xs', className)} {...props} />
))

export interface B4LinkProps {
  children?: React.ReactNode,
  className?: string,
  innerClassName?: string,
  to: string,
  color?: B4TextColor,
  target?: string,
  rel?: string
}
  
export const B4Link = memo(({children, className = null, to, target = null, rel = null, ...props}: B4LinkProps) => (
  <B4TextBase className={clsx('underline inline', className)} innerClassName="inline" {...props}><Link to={to} target={target} rel={rel}>{children}</Link></B4TextBase>
))