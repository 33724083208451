import React, { useEffect } from 'react';
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { useAuth, useMe } from '../../hooks';
import LayoutOld from '../../components/layout';
import { Suspense } from 'react';
import LandingLayout from '../../components/landing/components/layout';
import { storeRequestedLocation } from '../../utils/locationRestore';
import { getRoom, saveInviteCode, useRoomNavigate } from '../../utils';
import { changeLanguage } from "i18next"
import { B4BackgroundContext, B4BackgroundType } from '../../design/atomic/utils';
import { B4BackgroundDark, B4BackgroundLight } from '../../design/atomic/layout';
import { B4BackgroundSwarm } from '../../design/atomic/animation';

export const GuestRoute = ({children}) => {
  const { loading, data } = useAuth();
  const navigate = useRoomNavigate()

  if (loading || !data) return null;

  if (data.isLoggedIn) {
    setTimeout(() => navigate('/dashboard'))
  }

  return <Suspense fallback={<LandingLayout />}>{children}</Suspense>;
};

export const GuestRouteIgnoreLoggedIn = ({children}) => <Suspense fallback={<LandingLayout />}>{children}</Suspense>

export const UserRoute = ({children}) => {
  const { loading, data } = useAuth();
  const { data: dataMe } = useMe({fetchPolicy: 'network-only'})
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (dataMe) {
      changeLanguage(dataMe.me.language) // adopt language if me data is available
    }
  }, [dataMe])

  if (loading || !data) return null;

  if (!data.isLoggedIn) {
    const inviteCode = searchParams.get('code')
    if (inviteCode) {
      saveInviteCode(inviteCode)
    }
    storeRequestedLocation(location)
    setTimeout(() => navigate('/landing'))
  }

  return <Suspense fallback={<LayoutOld header loading />}>{children}</Suspense>;
};

export const RoomRoute = ({children}) => {
  const navigate = useNavigate()

  if (!getRoom()) {
    setTimeout(() => navigate('/landing'))
  }

  return <Suspense fallback={<LayoutOld header loading />}>{children}</Suspense>;
};

export const BackgroundDarkWrapper = ({swarm = false}) => {
  return (
    <>
      {swarm && <B4BackgroundSwarm />}
      <B4BackgroundDark />
      <B4BackgroundContext.Provider value={{bgType: B4BackgroundType.DARK}}>
        <Outlet />
      </B4BackgroundContext.Provider>
    </>
  )
}

export const BackgroundLightWrapper = () => {
  return (
    <>
      <B4BackgroundLight />
      <B4BackgroundContext.Provider value={{bgType: B4BackgroundType.LIGHT}}>
        <Outlet />
      </B4BackgroundContext.Provider>
    </>
  )
}