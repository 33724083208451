import {gql, useQuery} from '@apollo/client';

const ANSWERS_QUERY = gql`
  query ($conversation_id: ID!) {
    answersForDashboardAnimation(conversation_id: $conversation_id) {
      id
      answer
    }
  }
`;


export const useAnswersForDashboardAnimation = (options = {}) => useQuery(ANSWERS_QUERY, options);

export const ALL_ANSWERS_QUERY = gql`
  query ($conversation_id: ID!) {
    allAnswers(conversation_id: $conversation_id) {
      id
      answer
      wins
      losses
      score
      rank
      approved
      user_id
    }
  }
`;

export const useAllAnswers = (options = {}) => useQuery(ALL_ANSWERS_QUERY, options);