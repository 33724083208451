import { AppBar, Toolbar } from '@mui/material';

import React from 'react';

import Logo from '../../../design/logo';

const Header = () => (
  <>
    <AppBar position='fixed' className='shadow-sm pt-safe z-10'>
      <Toolbar variant="dense" className='px-6'>
        <Logo className='mx-auto' />
      </Toolbar>
    </AppBar>
    <div className='pt-safe'>
      <Toolbar className='px-6' />
    </div>
  </>
);

export default Header;
