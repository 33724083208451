import { gql, useMutation } from '@apollo/client';
import { ALL_ANSWERS_QUERY } from '../queries/useAnswers';
import { DELETED_ANSWERS_QUERY } from '../queries/useDeletedAnswers';
import { APPROVAL_ANSWER_QUERY } from '../queries/useApprovalAnswers';

const DELETE_ANSWER = gql`
  mutation deleteAnswer($id: ID!) {
    deleteAnswer(id: $id) {
      id
    }
  }
`;

const useDeleteAnswer = ({ conversationId }) => {
  return useMutation(DELETE_ANSWER, {
    update(cache, { data: { deleteAnswer } }) {
      cache.evict({
        id: cache.identify(deleteAnswer),
      });
      cache.gc();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ALL_ANSWERS_QUERY,
        variables: { conversation_id: conversationId },
      },
      {
        query: DELETED_ANSWERS_QUERY,
        variables: { conversation_id: conversationId },
      },
      {
        query: APPROVAL_ANSWER_QUERY,
        variables: { conversation_id: conversationId },
      }
    ]
  });
};

export default useDeleteAnswer;
