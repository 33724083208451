import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation (
    $name: String
    $age: Int
    $gender: Int
    $region: String
    $education: String
    $country: String
    $website: String
    $about: String
    $email: String
    $language: String
    $translation_enabled: Boolean
    $town: ID
    $notifications_email: Boolean
    $notifications_push: Boolean
    $share_email: Boolean
    $dyn_1: String
    $dyn_2: String
    $dyn_3: String
    $welcome_shown: Boolean
    $current_tenant_id: ID

  ) {
    updateUser(
      input: {
        name: $name
        gender: $gender
        age: $age
        region: $region
        education: $education
        country: $country
        email: $email
        about: $about
        website: $website
        language: $language
        translation_enabled: $translation_enabled
        town: { connect: $town }
        notifications_email: $notifications_email
        notifications_push: $notifications_push
        share_email: $share_email
        dyn_1: $dyn_1
        dyn_2: $dyn_2
        dyn_3: $dyn_3
        welcome_shown: $welcome_shown
        current_tenant_id: $current_tenant_id
      }
    ) {
      id
      name
      gender
      age
      region
      education
      country
      email
      about
      website
      language
      translation_enabled
      notifications_email
      notifications_push
      share_email
      welcome_shown
      current_tenant_id
      town {
        id
        code
        name
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation {
    deleteAccount
  }
`;
