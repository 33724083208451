import {AppBar, Toolbar} from '@mui/material';
import React, {ReactNode} from 'react';
import Logo from '../../design/logo/index';
import { useTenant } from '../../hooks';
import ActivityIndicator from './activity-indicator';
import LanguageButton from './language-button';
import AdminButton from './admin-button';

type Props = {
  afterHeader?: ReactNode;
  onBack?: Function;
  title?: ReactNode;
  additionalIcons?: ReactNode;
};

const TopBar = ({ afterHeader, title, additionalIcons = null}: Props) => {
  const tenant = useTenant()


  return (
    <AppBar className='pt-safe z-10 sticky top-0'>
      <Toolbar variant="dense" className='flex px-0'>
        <div className='flex-1 min-w-0'>
          { tenant?.image && <div className='hidden md:block text-primary font-semibold px-4 whitespace-nowrap overflow-ellipsis overflow-hidden'>{tenant.about_heading}</div> }
        </div>
        {title || <Logo className='flex-none' />}
        <div className='flex-1 flex justify-end mr-2'>
          { additionalIcons }
          <LanguageButton/>
          <AdminButton />
        </div>
      </Toolbar>
      <div className='w-full'>{afterHeader}</div>
      <ActivityIndicator/>
    </AppBar>
  );
};

export default TopBar;
