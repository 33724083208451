import {gql, useQuery} from '@apollo/client';

export const DELETED_ANSWERS_QUERY = gql`
  query ($conversation_id: ID!) {
    deletedAnswers(conversation_id: $conversation_id) {
      id
      answer
    }
  }
`;

export const useDeletedAnswers = (options = {}) => useQuery(DELETED_ANSWERS_QUERY, options);