import { IconButton } from '@mui/material';
import { useState } from 'react';
import CustomizedModal from './customized-modal';

export const MenuItem = ({ children, onClick,icon = null }) => {
  return (
    <li
      className='space-x-0.5 border-t flex w-full py-4 px-6 font-medium items-center justify-left cursor-pointer text-sm'
      onClick={onClick}
    >
      {icon && <div className='w-8 '>{icon}</div>}
      <div>{children}</div>
    </li>
  );
};

export const MenuModal = ({ title, children, open, onClose}) => {
  return (
    <CustomizedModal
      open={open}
      onClose={onClose}
    >
      <div className='flex flex-col items-center h-full justify-end'>
        <ul className='w-11/12 bg-white relative rounded overflow-hidden mb-20 z-50 max-w-md'>
          <li
            className='flex justify-center py-4 text-center font-medium tracking-wider text-lg text-light '
          >
            {title}
          </li>
          <div onClick={onClose}>
            {children}
          </div>
        </ul>
      </div>
    </CustomizedModal>
  )
}

export const ContextMenu = ({icon, title, children}) => {
  const [open, setOpen] = useState(false)
  return <>
    <IconButton className='p-1 -mr-1' onClick={() => setOpen(true)}>{icon}</IconButton>
    <MenuModal title={title} open={open} onClose={() => setOpen(false)}>{children}</MenuModal>
  </>
};