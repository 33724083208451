import styled from 'styled-components';

import React, { ReactNode } from 'react';
import TopBar from './top-bar';
import FooterNavigation, {NavEntry} from './footer-navigation';

import { Loading } from '../../design/ui/loading';
import clsx from 'clsx';
import { PropsChildren, PropsClassName } from '../../design/ui/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

type LayoutOldProps = {
  header?: boolean;
  afterHeader?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  onBack?: () => void;
  className?: string;
  loading?: boolean;
  headerTitle?: ReactNode;
};

const LayoutOld = ({
  className,
  header,
  onBack,
  afterHeader,
  footer,
  children,
  loading,
  headerTitle,
}: LayoutOldProps) => {
  return (
    <Container className={className}>
      {header && <TopBar afterHeader={afterHeader} onBack={onBack} title={headerTitle} />}
      <Scrollable>{loading ? <Loading show /> : children}</Scrollable>
      {footer && <FooterNavigation />}
    </Container>
  );
};

export default LayoutOld;

interface LayoutContentProps extends PropsChildren, PropsClassName {
  footer?: boolean;
  full?: boolean;
}

export const LayoutContent = ({className, children, footer = true}: LayoutContentProps) => (
  <div className='w-full flex-1 bg-std-bg flex flex-col'>
    <main className={clsx('pt-3 px-3 flex-1 max-w-full w-[32rem] mx-auto', className)} style={{paddingBottom: footer ? '3.5rem' : null, width: '32rem'}}>
      { children }
    </main>
  </div>
)

interface LayoutProps extends PropsChildren, PropsClassName {
  afterHeader?: ReactNode;
  footer?: boolean;
  header?: boolean;
  additionalIcons?: ReactNode;
  navReport?: NavEntry;
}

export const Layout = ({className = null, children = null, afterHeader = null, footer = true, header = true, additionalIcons = null, navReport = null}: LayoutProps) => (
  <div className='min-h-full flex flex-col'>
    { header && <TopBar afterHeader={afterHeader} additionalIcons={additionalIcons} /> }
    <LayoutContent className={className} footer={footer}>{children}</LayoutContent>
    { footer && <FooterNavigation navReport={navReport} /> }
  </div>
)