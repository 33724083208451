import { useQuery, gql } from '@apollo/client';

export const RESONANCE_QUERY = gql`
  query ($conversation_id: ID!, $battles: Boolean, $conversationStats: Boolean) {
    resonance(id: $conversation_id, battles: $battles, conversationStats: $conversationStats) {
      conversation {
        id
        question
        expired
        user {
          id
          gender
          name
          avatar
          town {
            id
            name
          }
          country
          age
        }
        created_at
        analysis_state
      }
      conversationStats {
        Choices
        Participants
        PromptsDone
      }
      answers {
        id
        answer
        score
        wins
        losses
        participants
        rank
        has_next_steps
        discarded
        reports_count
        addon_answers_count
        match
        created_at
        duplicate_of
        user {
          id
          name
          gender
          avatar
          website
          town {
            name
          }
          age
          has_email
        }
      }
    }
  }
`;

const useResonance = (options = {}) => {
  return useQuery(RESONANCE_QUERY, options);
};

export default useResonance;
