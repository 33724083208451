import React from 'react';
import {useTranslation} from 'react-i18next';
import VotingWrapper from './votingWrapper';
import { useParams } from 'react-router-dom';
import { useInnovatorStats, useResonance, useTenantMinimal } from '../../hooks';
import { MdHourglassTop, MdShare } from 'react-icons/md';
import useConversation from '../../hooks/queries/useConversation';
import { B4Text, B4TextColor, B4TextEmp, B4TextMedium, B4TextSmall } from '../../design/atomic/text';
import { B4SpaceHorizontal, B4SpaceVertical } from '../../design/atomic/layout';
import { B4Bubble, B4BubbleColor, B4Corner } from '../../design/atomic/bubble';
import { B4HeaderSub, B4HeaderHelp, B4HeaderBackBtn } from '../../design/atomic/header-footer';
import { prefixRoomLink } from '../../utils';
import { B4ButtonColor, B4ButtonOutlined } from '../../design/atomic/button';
import { genVotingProgressLevel } from './votingProgress';
import { formatNumber } from '../../design/atomic/utils';

const WAITING_PLACEHOLDER = <div className='flex justify-center text-b4-secondary text-2xl'><MdHourglassTop/></div>

const VotingStats = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const tenant = useTenantMinimal();

  // optimize this query. T
  const { loading: loadingConversation, data: {conversation} = {} } = useConversation({
    variables: { conversation_id: id },
    notifyOnNetworkStatusChange: true,
  });

  const { data: dataResonance, loading: loadingResonance } = useResonance({
    variables: { conversation_id: id },
    fetchPolicy: 'cache-and-network', // network because win/losses might have changed in the meantime,
    // cache because when coming back from deletion or duplicate resolution,
    // the updates are taken from the cache
  });

  const { data: dataResonanceBattles, loading: loadingResonanceBattles } = useResonance({
    variables: { conversation_id: id, battles: true },
    fetchPolicy: 'cache-and-network', // network because win/losses might have changed in the meantime,
    // cache because when coming back from deletion or duplicate resolution,
    // the updates are taken from the cache
  });

  const { data: innovatorStats, loading: loadingInnovatorStats } = useInnovatorStats({
    variables: { conversation_id: id },
    fetchPolicy: 'cache-and-network',
  });

  const allLoading = loadingConversation || loadingResonance || loadingResonanceBattles || loadingInnovatorStats;

  const shareQuestion = async() => {
    const shareData = {
      title: tenant?.about_heading,
      text: conversation.question,
      url: window.location.origin + prefixRoomLink(`/rate/question/${conversation.id}/rate`)
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      // sharing failed, we ignore this
    }
  }

  return (
    <VotingWrapper
      loading={allLoading}
      conversation={conversation}
      headerBtns={<>
        <B4HeaderHelp html={t('txtMyIdeasHelp')}/>
        {navigator.share && <B4ButtonOutlined color={B4ButtonColor.BLUE} onClick={shareQuestion} wFull={false}><MdShare /></B4ButtonOutlined>}
      </>}
      headerMiddle={<B4HeaderBackBtn/>}
    >
      <B4SpaceVertical>
        { (innovatorStats?.GetInnovatorStats && dataResonance?.resonance?.answers) ? <>
          <B4TextEmp className="text-center" color={B4TextColor.GREEN} html={t('txtReachedLevel', {level: genVotingProgressLevel(innovatorStats?.GetInnovatorStats?.prompts_done)})} />
          <B4Text color={B4TextColor.WHITE} className='text-center'>{formatNumber(innovatorStats?.GetInnovatorStats?.prompts_done)} {t('txtBattles')} / {formatNumber(dataResonance?.resonance?.answers.length)} {t('lblIdeas')}</B4Text>
        </> : null}
        { dataResonance?.resonance?.answers.length ? <>
          <B4HeaderSub 
            middle={<B4TextEmp className='text-center' color={B4TextColor.GREEN}>{t('lblMyIdeas')}</B4TextEmp>}
          />
          {
            dataResonance?.resonance?.answers.map((answer, i) => (
              <B4Bubble color={B4BubbleColor.GREEN_NEON} key={i} position={i + 1} sharpCorner={
                (i % 3 === 1 && B4Corner.BOTTOM_LEFT) ||
                (i % 3 === 2 && B4Corner.TOP_RIGHT) ||
                B4Corner.TOP_LEFT
              }>
                <B4SpaceHorizontal className="flex items-center">
                  <div className='flex flex-col items-center'>{
                    answer.rank ? <>
                      <B4TextSmall color={B4TextColor.WHITE}>{t('txtRank')}</B4TextSmall>
                      <B4TextMedium color={B4TextColor.WHITE}>{answer.rank}</B4TextMedium>
                    </> : 
                    <B4Text color={B4TextColor.WHITE}><MdHourglassTop/></B4Text>
                  }</div>
                  <B4Text color={B4TextColor.WHITE} className="font-bold">{answer.answer}</B4Text>
                </B4SpaceHorizontal>
              </B4Bubble>
            ))}
          </> : null}
        <B4HeaderSub 
          middle={<B4TextEmp className='text-center' color={B4TextColor.GREEN}>{t('lblMyBattles')}</B4TextEmp>}
        />
        { !dataResonanceBattles?.resonance?.answers?.length && WAITING_PLACEHOLDER }
        {
          dataResonanceBattles?.resonance?.answers && dataResonanceBattles?.resonance?.answers
          .map((answer, i) => (
            <B4Bubble color={B4BubbleColor.BLUE} key={i} position={i} sharpCorner={
              (i % 3 === 1 && B4Corner.BOTTOM_LEFT) ||
              (i % 3 === 2 && B4Corner.TOP_RIGHT) ||
              B4Corner.TOP_LEFT
            }>
              <B4SpaceHorizontal className="flex items-center">
                <B4TextMedium color={B4TextColor.WHITE}>{i + 1}</B4TextMedium>
                <div>{answer.answer}</div>
              </B4SpaceHorizontal>
            </B4Bubble>
          ))
        }
      </B4SpaceVertical>
    </VotingWrapper>
  )
}

export default VotingStats;
