import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BottomNavigation,
} from '../../design/ui/bottom-navigation';
import { useMe } from '../../hooks';
import { BottomNavigationAction } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prefixRoomLink } from '../../utils';
import { IoMdHome } from 'react-icons/io';
import { IoInformationCircle, IoStatsChart } from 'react-icons/io5';
import { MdAddCircle, MdManageAccounts } from 'react-icons/md';
import clsx from 'clsx';
import { IconType } from 'react-icons/lib';


export interface NavEntry {
  label: string;
  icon: IconType;
  path: string;
}

interface Props {
  navReport?: NavEntry;
}

const FooterNavigation = ({navReport} : Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data } = useMe();
  const { t } = useTranslation();

  const onChange = (event, value) => {
    navigate(value);
  };

  // Minor hack. TODO - Find a better way
  const locationValue = pathname.startsWith(prefixRoomLink('/resonance-list'))
    ? prefixRoomLink('/resonance-list')
    : pathname

  const items = useMemo(() => {
    const adminItems = [
      { label: 'txtAllQuestions', icon: IoMdHome, path: '/dashboard' },
      { label: 'txtAboutUs', icon: IoInformationCircle, path: '/about-us' },
      { label: 'txtAskAQuestionTitle', icon: MdAddCircle, path: '/question-create' },
      ...(navReport ? [navReport] : [{ label: 'txtReport', icon: IoStatsChart, path: '/resonance-list' }]),
      { label: 'txtMyProfile', icon: MdManageAccounts, path: '/settings' },
    ];
  
    const userItems = [
      { label: 'txtAboutUs', icon: IoInformationCircle, path: '/about-us' },
      { label: 'txtAllQuestions', icon: IoMdHome, path: '/dashboard' },
      ...(navReport ? [navReport] : []),
      { label: 'txtMyProfile', icon: MdManageAccounts, path: '/settings' },
    ];
  
    const guestItems = [
      { label: 'txtAllQuestions', icon: IoMdHome, path: '/dashboard' },
      ...(navReport ? [navReport] : []),
      { label: 'txtAboutUs', icon: IoInformationCircle, path: '/about-us' },
    ];
    
    if (!data) return [];
    if (data.me.role === 'User') {
      return data.me.has_email ? userItems : guestItems;
    }
    return adminItems;
  }, [data, navReport]);

  return (
    <div className='border-t z-40 w-full bg-white fixed bottom-0'>
      <BottomNavigation
        className='max-w-md mx-auto'
        showLabels
        value={locationValue}
        onChange={onChange}
      >
        {items.map(({ label, icon: Icon, path }, i) => {
          const value = prefixRoomLink(path)
          const active = value === locationValue
          return (
            <BottomNavigationAction
              className={clsx({
                '!text-super-light': !active,
                '!text-primary': active
              })}
              key={i}
              value={value}
              icon={<Icon className='w-8 h-6' />}
              label={<span className='truncate'>{t(label)}</span>}
            />
          )
        })}
      </BottomNavigation>
    </div>
  );
};

export default FooterNavigation;
