import { useQuery, gql } from '@apollo/client';

const PROFILE_QUERY = gql`
  query ($userId: ID!) {
    user(id: $userId) {
      id
      name
      age
      country
      avatar
      is_innovator
      role
      verified
      website
      about
      conversation_count
      answer_count
      popular_answers_count
    }
  }
`;

const useProfile = ({ variables }) => {
  return useQuery(PROFILE_QUERY, { variables });
};

export default useProfile;
