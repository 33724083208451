import { Check, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLanguage } from 'react-icons/md';
import { useAuth, useMe } from '../../hooks';
import i18n from '../../_i18n/i18n';
import { useApolloClient } from '@apollo/client';
import { useUpdateUser } from '../screens/profile/edit/hooks';

const languages = [
  {
    label: 'txtGerman',
    value: 'de',
  },
  {
    label: 'txtEnglish',
    value: 'en',
  },
  {
    label: 'txtFrench',
    value: 'fr',
  },
  {
    label: 'txtItalian',
    value: 'it',
  },
  {
    label: 'txtPortuguese',
    value: 'pt',
  },
  {
    label: 'txtSpanish',
    value: 'es',
  },
  {
    label: 'txtSwedish',
    value: 'sv',
  },
]

const LanguageButtonUnauthorized = ({postMenuItems = null, onPreLanguageChange = null, onPostLanguageChange = null}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelectLanguage = async (language: string) => {
    if (onPreLanguageChange) {
      await onPreLanguageChange(language)
    }
    await i18n.changeLanguage(language);
    if (onPostLanguageChange) {
      await onPostLanguageChange(language)
    }
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MdLanguage/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {
          languages.map(({label, value}) => {
            const active = i18n.language === value
            return (
              <MenuItem key={value} onClick={() => handleSelectLanguage(value)} disabled={active}>
                { active && <ListItemIcon><Check/></ListItemIcon> }
                <ListItemText inset={!active}>{t(label)}</ListItemText>
              </MenuItem>
            )
          })
        }
        {postMenuItems && <Divider/>}
        {postMenuItems}
      </Menu>
    </>
  );
};

const LanguageButtonAuthorized = () => {
  const { t } = useTranslation()
  const { data } = useMe()
  const client = useApolloClient()
  const [updateUser] = useUpdateUser()

  const onPreLanguageChange = async (language: string) => {
    await updateUser({
      variables: {
        language
      },
    });
  }
  const onPostLanguageChange = async (language: string) => {
    await client.resetStore();
  }
  const handleToggleTranslation = async () => {
    await updateUser({
      variables: {
        translation_enabled: !data.me.translation_enabled
      },
    });
    client.resetStore().then(_ => {});
  }

  return (
    <LanguageButtonUnauthorized postMenuItems={
      <MenuItem onClick={handleToggleTranslation}>
        <ListItemIcon>{data?.me.translation_enabled ?<CheckBox /> : <CheckBoxOutlineBlank/>}</ListItemIcon><ListItemText>{t('txtTranslation')}</ListItemText>
      </MenuItem>
    } onPreLanguageChange={onPreLanguageChange} onPostLanguageChange={onPostLanguageChange}/>
  );
};

const LanguageButton = () => {
  const { loading, data: authData } = useAuth()
  if (loading || !authData.isLoggedIn) return <LanguageButtonUnauthorized/>
  return <LanguageButtonAuthorized/>
}


export default LanguageButton;