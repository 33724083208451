import { Button } from "@mui/base";
import { animated, useSpring } from '@react-spring/web'
import clsx from "clsx";
import { useEffect } from "react";

export enum B4ButtonColor {
  BLUE,
  BLUE_LIGHT,
  GREEN,
  RED,
  WHITE,
  GREY
}
interface B4ButtonProps {
  children: React.ReactNode,
  color?: B4ButtonColor,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  disabled?: boolean,
  timeout?: boolean,
  full?: boolean,
}
export const B4Button = ({children, onClick = null, color = B4ButtonColor.GREEN, disabled = false, timeout = false, full = true}: B4ButtonProps) => {
  const [styles, api] = useSpring(() => ({
    from: { width: '100%' },

  }), [])

  useEffect(() => {
    if (timeout) {
      api.start({
        from: {width: '100%'},
        to: { width: '0%' },
        config: { duration: 7500 }
      })

      const timeout = setTimeout(() => {
        onClick && onClick(null)
      }, 7500)

      return () => clearTimeout(timeout)
    }
  }, [timeout]);

  return (
    <Button
      disabled={disabled}
      className={clsx('text-b4-primary rounded font-medium text-base relative', {
        'w-full': full,
        'bg-b4-secondary active:bg-b4-question active:text-white': !disabled && color === B4ButtonColor.GREEN,
        'bg-b4-question text-white': !disabled && color === B4ButtonColor.BLUE,
        'bg-red-500 text-white': !disabled && color === B4ButtonColor.RED,
        'bg-white': color === B4ButtonColor.WHITE,
        'bg-gray-400 text-white': disabled && color !== B4ButtonColor.WHITE,
        'text-gray-400': disabled && color === B4ButtonColor.WHITE,
      })}
      onClick={onClick}>
        <div className="p-2">{ children }</div>
        { timeout && <div className="absolute bottom-0 flex justify-end w-full"><animated.div style={styles} className="h-0.5 bg-b4-primary" /></div>}
    </Button>
  )
}

export const B4IconButtonNoP = ({children, className = null, onClick = null, disabled = false, color = null, big = false, transparent = false}) => (
  <Button className={clsx('rounded-full flex justify-center items-center text-b4-primary',{
    'bg-white shadow-md shadow-b4-primary': color === B4ButtonColor.WHITE && !disabled,
    'bg-b4-primary text-white shadow-md shadow-white': color === B4ButtonColor.BLUE && !disabled,
    'bg-b4-secondary active:bg-b4-question active:text-white': color === B4ButtonColor.GREEN && !disabled,
    'bg-red-500 active:bg-b4-question text-white': color === B4ButtonColor.RED && !disabled,
    'active:bg-[#E3E4EE] ': !disabled && color === null,
    'text-gray-600': disabled,
    'opacity-50': transparent,
    'text-2xl': big,
    'text-xl': !big,
  } , className)} onClick={onClick} disabled={disabled}>{children}</Button>
)

export const B4IconButton = ({className = null, children, color = null, ...rest}) => (
  <B4IconButtonNoP className={clsx('p-2', {
    '-m-2': color === null
  }, className)} color={color} {...rest}>{children}</B4IconButtonNoP>
)

export const B4IconButtonWithText = ({icon, text, color = B4ButtonColor.GREEN, onClick = null, disabled = false}) => (
  <Button disabled={disabled} className={clsx('p-2 active:bg-[#ffffff22] cursor-pointer', {
    'text-[#C3BFD7]': color === B4ButtonColor.GREY || (color === B4ButtonColor.GREEN && disabled),
    'text-b4-secondary': color === B4ButtonColor.GREEN && !disabled,
  })} onClick={onClick}>
    <div className="text-4xl flex justify-center">{icon}</div>
    <div className="text-sm flex justify-center">{text}</div>
  </Button>
)

export enum B4ButtonOutlinedBorder {
  ALL,
  BOTTOM,
  NONE,
}

export const B4ButtonOutlined = ({children, wFull = true, onClick = null, className = null, color = B4ButtonColor.WHITE,
                                  sharp = false, icon = null, border = B4ButtonOutlinedBorder.ALL}) => (
  <Button className={clsx('font-white p-1 font-normal active:bg-[#ffffff22]', {
    'border': border === B4ButtonOutlinedBorder.ALL,
    'border-b': border === B4ButtonOutlinedBorder.BOTTOM,
    'rounded-full': !sharp && border === B4ButtonOutlinedBorder.ALL,
    'rounded': sharp && border === B4ButtonOutlinedBorder.ALL,
    'w-full': wFull,
    'border-white text-white': color === B4ButtonColor.WHITE,
    'border-b4-primary text-b4-primary': color === B4ButtonColor.BLUE,
    'border-b4-title text-b4-title': color === B4ButtonColor.BLUE_LIGHT,
    'border-b4-secondary text-b4-secondary': color === B4ButtonColor.GREEN,
  }, className)} onClick={onClick}><div className="flex items-center space-x-1 justify-center"><div>{children}</div>{icon}</div></Button>
)