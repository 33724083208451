import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"
import { B4IconButton } from "../button"
import { B4SpaceHorizontal } from "../layout"
import { B4Text, B4TextColor } from "../text"
import { B4ControlProps, B4InputCommonProps } from "./commons"
import { Controller } from "react-hook-form"

interface B4CheckBoxProps extends B4InputCommonProps {
  disabled?: boolean
  value: boolean,
  onClick?: () => void,
}

export const B4CheckBox = ({label, value = false, onClick = null, disabled = false}: B4CheckBoxProps) => (
  <B4SpaceHorizontal className="flex items-center">
    <B4IconButton disabled={disabled} onClick={onClick}>{value ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</B4IconButton>
    <B4Text color={disabled ? B4TextColor.DISABLED : undefined}>{label}</B4Text>
  </B4SpaceHorizontal>
)

interface B4ControllerCheckBoxProps extends B4InputCommonProps, B4ControlProps {}

export const B4ControllerCheckBox = ({name, label, control, required = false, disabled = false}: B4ControllerCheckBoxProps) => (
  <Controller<Record<string, boolean>>
    control={control}
    name={name}
    rules={{ required }}
    disabled={disabled}
    render={({ field: { onChange, value, disabled } }) => (
      <B4CheckBox label={label} required={required} value={value} onClick={() => onChange(!value)} disabled={disabled} />
    )}
  />
)