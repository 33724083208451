import { lazy } from 'react';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import InviteCode from '../../components/landing/invite-code';
import EmailSignup from '../../components/signup/email-signup';
import VotingStats from '../../components/voting/votingStats';
import { BackgroundLightWrapper, BackgroundDarkWrapper, GuestRoute, RoomRoute, UserRoute, GuestRouteIgnoreLoggedIn } from './routeHelpers';

const Dashboard = lazy(() => import('../../components/screens/overview'));
const Resonance = lazy(() => import('../../components/screens/resonance-list/resonance'));
const ResonanceStats = lazy(() => import('../../components/screens/resonance-list/resonance-stats'));
const DuplicateResolution = lazy(() => import('../../components/screens/resonance-list/duplicate-resolution'));
const Settings = lazy(() => import('../../components/screens/profile/settings'));
const FurtherFeatures = lazy(() => import('../../components/screens/profile/further-features'));
const AnswerNextSteps = lazy(() => import('../../components/answers/answer-next-steps'));
const AnswerShow = lazy(() => import('../../components/answers/show'));
const AnswerReports = lazy(() => import('../../components/answers/reports'));
const AnswerCreate = lazy(() => import('../../components/answers/create'));
const CompleteProfile = lazy(() => import('../../components/signup/complete'));
const Landing = lazy(() => import('../../components/landing'));
const Unsubscribe = lazy(() => import('../../components/landing/unsubscribe'));
const Login = lazy(() => import('../../components/login'));
const Signup = lazy(() => import('../../components/signup'));
const Voting = lazy(() => import('../../components/voting/voting'));
const Countdown = lazy(() => import('../../components/voting/countdown'));
const ChangePassword = lazy(() => import('../../components/change-password'));
const ForgotPassword = lazy(() => import('../../components/forgot-password'));
const UserRoomWrapper = lazy(() => import('../../components/room/user-room-wrapper'));
const RoomPicker = lazy(() => import('../../components/room/room-picker'));
const ConfigNotifications = lazy(() => import('../../components/config-notifications'));
const TenantUpsert = lazy(() => import('../../components/admin/tenant-upsert'));
const AdminWrapper = lazy(() => import('../../components/admin/admin-wrapper'));
const TenantList = lazy(() => import('../../components/admin/tenant-list'));
const TenantDashboard = lazy(() => import('../../components/admin/tenant-dashboard'));
const ConversationDashboard = lazy(() => import('../../components/admin/conversation-dashboard'));
const ConversationUpsert = lazy(() => import('../../components/admin/conversation-upsert'));
const IdeaUpsert = lazy(() => import('../../components/admin/idea-upsert'));


const Routing = () => {
  return (
    <Routes>
      <Route element={<BackgroundLightWrapper/>}>
        <Route path='/change-password' element={<UserRoute><ChangePassword /></UserRoute>} />
        <Route path='/config-notifications' element={<UserRoute><ConfigNotifications /></UserRoute>} />
      </Route>

      <Route element={<BackgroundDarkWrapper swarm={true}/>}>
        <Route path='/landing' element={<GuestRoute><Landing /></GuestRoute>} />
        <Route path='/unsubscribe' element={<GuestRouteIgnoreLoggedIn><Unsubscribe /></GuestRouteIgnoreLoggedIn>} />
        <Route path='/forgot-password' element={<GuestRoute><ForgotPassword /></GuestRoute>} />
        <Route path='/invite-code' element={<GuestRoute><InviteCode /></GuestRoute>} />
        <Route path='/register/otp' element={<GuestRoute><EmailSignup /></GuestRoute>} />
        <Route path='/register/guest' element={<RoomRoute><Signup method={'guest'} /></RoomRoute>} />
        <Route path='/register/email_otp' element={<RoomRoute><Signup method={'email_otp'} /></RoomRoute>} />
        <Route path='/register/email' element={<RoomRoute><Signup method={'email'} /></RoomRoute>} />
        <Route path='/login' element={<GuestRoute><Login /></GuestRoute>} />
        <Route path='/pick-room/no-auto' element={<UserRoute><RoomPicker auto={false} /></UserRoute>} />
        <Route path='/pick-room' element={<UserRoute><RoomPicker /></UserRoute>} />
        <Route path='/settings' element={<UserRoute><Settings /></UserRoute>} />
        <Route path='/further-features' element={<UserRoute><FurtherFeatures /></UserRoute>} />
        <Route path='/room/:roomId' element={<UserRoomWrapper />}>
          <Route path='complete' element={<CompleteProfile />} />
          <Route path='complete/innovator' element={<CompleteProfile innovator={true} />} />
          <Route path='rate/question/:id/vote' element={<Voting />} />
          <Route path='rate/question/:id/rate' element={<Countdown />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='questions' element={<Dashboard doInitialScroll={true} />} />
        </Route>
      </Route>


      <Route element={<BackgroundDarkWrapper/>}>
        <Route path='/room/:roomId' element={<UserRoomWrapper />}>
          <Route path='complete/innovator' element={<CompleteProfile innovator={true} />} />
          <Route path='answer/:id/next-steps' element={<AnswerNextSteps />} />
          <Route path='answer/:id/show' element={<AnswerShow />} />
          <Route path='answer/:id/reports/:selectedTab' element={<AnswerReports />} />
          <Route path='answer/:id/reports' element={<AnswerReports />} />
          <Route path='rate/question/:id/stats' element={<VotingStats />} />
          <Route path='question/:id/answer/create' element={<AnswerCreate />} />
          <Route path='resonance-list/:id' element={<Resonance />} />
          <Route path='resonance-list/:id/duplicate-resolution/:answerId' element={<DuplicateResolution />} />
          <Route path='resonance-stats/:id' element={<ResonanceStats />} />
          <Route path='*' element={<Navigate to="dashboard" />} />
          <Route path='' element={<Navigate to="dashboard" />} />
        </Route>
      </Route>

      <Route path='/admin' element={<UserRoute><AdminWrapper /></UserRoute>}>
        <Route path='tenant' element={<TenantList/>} />
        <Route path='tenant/create' element={<TenantUpsert/>} />
        <Route path='tenant/:tenantId/edit' element={<TenantUpsert/>} />
        <Route path='tenant/:tenantId' element={<TenantDashboard/>} />
        <Route path='tenant/:tenantId/conversation/:conversationId' element={<ConversationDashboard/>} />
        <Route path='tenant/:tenantId/conversation/create' element={<ConversationUpsert/>} />
        <Route path='tenant/:tenantId/conversation/:conversationId/edit' element={<ConversationUpsert/>} />
        <Route path='tenant/:tenantId/conversation/:conversationId/idea/create' element={<IdeaUpsert/>} />
        <Route path='tenant/:tenantId/conversation/:conversationId/idea/:answerId/edit' element={<IdeaUpsert/>} />
        <Route path='' element={<Navigate to="tenant" />} />
      </Route>

      <Route path='*' element={<UserRoute><Navigate to='/pick-room' /></UserRoute>} />
    </Routes>
  );
};

const BrowserRouting = () => (
  <BrowserRouter>
    <Routing />
  </BrowserRouter>
);

export default BrowserRouting;
