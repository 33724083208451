import {gql, useQuery} from '@apollo/client';

export const CONVERSATIONS_QUERY = gql`
  query(
    $mine: Boolean = true
  ) {
    conversations(
      mine: $mine
    ) {
      id
      question
      ended
      ended_or_expired
      answer_count
      answer_not_approved_count
      has_non_seed_answers
      non_seed_answers
      created_at
      deleted_at
      user {
        id
        avatar
        name
        country
        town {
          id
          code
          name
        }
        age
      }
    }
  }
`;

const useConversations = (options = {}) => useQuery(CONVERSATIONS_QUERY, options);

export default useConversations;
