import { createContext, createRef, useEffect, useRef, useState } from "react";

export const useOutsideClick = (callback) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClick = (event) => {
      if (callback && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);

  return ref;
};

export const useKeyUp = (targetKey, callback) => {
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (callback && event.key === targetKey) {
        callback();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [targetKey, callback]);
};

export const useChangedEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
  }, deps);
}

export const debounce = (func, timeout) =>{
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const useScrollLock = (enabled = true) => {
  useEffect(() => {
    const handleScroll = (event) => {
      if (!enabled) {
        event.preventDefault();
      }
    };

    if (!enabled) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('scroll', handleScroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('scroll', handleScroll);
    };
  }, [enabled]);
};

export const useScrollYMoreThen = (position: number) => {
  const [moreThenPosition, setMoreThenPosition] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setMoreThenPosition(window.scrollY > position)
    }

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, []) // Empty dependency array ensures the effect runs only once when the component mounts

  return moreThenPosition
}


export enum B4BackgroundType {
  DARK,
  LIGHT
}

interface B4BackgroundContextProps {
  bgType: B4BackgroundType,
}

export const B4BackgroundContext = createContext<Partial<B4BackgroundContextProps>>({bgType: B4BackgroundType.DARK})

export const B4BackgroundContextRef = createRef()

export const formatNumber = (number?: number): string => {
	if (number === null || number === undefined) {
		return ''
	}
	return new Intl.NumberFormat().format(number)
};

export const formatISODate = (date?: string): string => {
  if (!date) {
    return ''
  }
  return new Date(date).toLocaleDateString()
}