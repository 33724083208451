import { useQuery, gql } from '@apollo/client';

const GET_AND_PROMPT_CHOICES = gql`
  query ($conversation_id: ID!) {
    GetAndPromptChoices(conversation_id: $conversation_id) {
      conversation {
        id
        active
        question
        language
        created_at
        expired
        ended
        user {
          id
          gender
          name
          avatar
          country
          age
          town {
            name
          }
        }
      }
      prompt {
        prompt_id
        question_id
        left_choice_id
        right_choice_id
        left_choice_text
        right_choice_text
        left_choice_reported
        right_choice_reported
        left_choice_mine
        right_choice_mine
      }
    }
  }
`;

const useGetAndPromptChoices = (options = {}) => {
  return useQuery(GET_AND_PROMPT_CHOICES, options);
};

export default useGetAndPromptChoices;
