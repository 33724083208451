/** @jsxImportSource @emotion/react */

import clsx from "clsx";
import { useContext } from "react";
import { B4LoadingContext } from "../../utils/b4Context";
import { B4BackgroundContext, B4BackgroundType } from "./utils";
import { css, keyframes } from "@emotion/react";


export const B4Header = ({children, fullScreen = false}) => {
  const { bgType } = useContext(B4BackgroundContext)
  const className = clsx({
    'shadow-b4-primary': bgType === B4BackgroundType.DARK,
  }, 'flex justify-center sticky top-0 z-20 bg-white shadow')

  return (
    <div className={className}>
      <div className={clsx('max-w-full h-b4-header flex justify-between items-center px-b4-std', {
        'w-b4-max': !fullScreen,
        'w-full': fullScreen
      })}>{children}</div>
    </div>
  )
}

export const B4Footer = ({children}) => (
  <div className="-mx-b4-std -mb-b4-std mt-b4-std sticky bottom-0">
    <div className="flex justify-center">
      <div className="max-w-full w-b4-max">{children}</div>
    </div>
  </div>
)

const B4Loading = () => {
  const ctContext = useContext(B4LoadingContext);

  const loadingMove = keyframes`
    from {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(100%);
    }
  `

  return (
    <div className={clsx('fixed top-0 left-0 right-0 z-[100]')} style={{
      opacity: ctContext.loading ? 1 : 0,
      transition: 'opacity .25s ease-in-out'
    }}>
      <div css={css`
        animation: ${loadingMove} 2000ms linear infinite;
      `}>
        <div className="bg-gradient-to-r from-b4-question to-b4-secondary h-1 w-1/2" />
      </div>
    </div>
  )
}

export const B4Layout = ({children = null, header = null, padding = true, fullScreen = false, vCentered = false}) => {
  return (<>
    <B4Loading/>
    <div className="min-h-full flex flex-col">
      {header}
      <div className={clsx('flex justify-center grow', {
        'items-center': vCentered
      })}>
        <div className={clsx('max-w-full', {
          'w-b4-max': !fullScreen,
          'w-full': fullScreen,
          'p-b4-std': padding
        })
        }>{
            children
        }</div>
      </div>
    </div>
  </>)
}

export const B4SpaceHorizontal = ({children, onClick = null, className = null}) => (
  <div className={clsx('flex space-x-b4-std', className)} onClick={onClick}>{children}</div>
)

export const B4SpaceVertical = ({children, className = null}) => (
  <div className={clsx('space-y-b4-std', className)}>{children}</div>
)

export const B4RulerHorizontal = () => (
  <hr className="bg-gray-300 h-[0.0625rem] border-0" />
)

export const B4RulerVertical = ({className = null}) => (
  <div className={clsx('bg-gray-300 w-[0.0625rem]', className)} />
)

export const B4BackgroundDark = () => (
  <div className="fixed inset-0 z-[-2] bg-b4-bg-dark">
  </div>
)

export const B4BackgroundLight = () => (
  <div className="fixed inset-0 z-[-2] bg-b4-bg-light">
  </div>
)

export const B4ScreenCorner = ({children}) => (
  <div className="fixed bottom-0 left-0 right-0 flex justify-center z-10">
    <div className="max-w-full w-b4-max flex justify-end p-b4-std">
      { children }
    </div>
  </div>
)