import { endsWith, isString } from "lodash";
import { Location, matchPath, useNavigate } from "react-router";
import { prefixRoomLink } from "./auth";

const INITIALLY_REQUESTED_LOCATION = 'initially-requested-location';

const getPath = () : string => localStorage.getItem(INITIALLY_REQUESTED_LOCATION) || prefixRoomLink('/dashboard');

const cleanup = () => localStorage.removeItem(INITIALLY_REQUESTED_LOCATION)

const getRequestedPathAndCleanup = () : string => {
	const path = getPath();
	cleanup();
	return path;
}

export const storeRequestedLocation = (locationOrPath: Location | string) => {
	const path = isString(locationOrPath) ? locationOrPath : locationOrPath.pathname;
	if (path !== '/' && !endsWith(path, "pick-room")) {
		localStorage.setItem(INITIALLY_REQUESTED_LOCATION, path);
	}
}

export const useRedirectAfterFinished = () => {
	const navigate = useNavigate()
	return () => {
		setTimeout(() => navigate(getRequestedPathAndCleanup()));
	}
}

export const useRedirectAfterCanceled = () => {
	const navigate = useNavigate()

	return () => {
		const path = getRequestedPathAndCleanup()
		const answerCreateMatch = matchPath({path: '/room/:roomId/question/:id/answer/create'}, path)

		if (answerCreateMatch) { // cannot go back to answer creation because it will redirect the user back
			navigate(prefixRoomLink(`/rate/question/${answerCreateMatch.params.id}/vote`))
		} else {
			navigate(path)
		}
	}
}