import { useState } from "react"
import { B4ModalConfirm } from "../../design/atomic/modal"
import { useTranslation } from "react-i18next"
import { B4SpaceHorizontal, B4SpaceVertical } from "../../design/atomic/layout"
import { B4Text, B4TextBig } from "../../design/atomic/text"
import clsx from "clsx"
import { isNumber, range } from "lodash"
import { useResonance } from "../../hooks"
import { useChangedEffect } from "../../design/atomic/utils"
import { GiPartyPopper } from "react-icons/gi"

const VOTING_PROGRESS_MAX_PROMPTS = 150
const VOTING_PROGRESS_STEPS_PROMPT = 30
const VOTING_PROGRESS_MAX_IDEAS = 10
const VOTING_PROGRESS_STEPS_IDEAS = 2

const genRange = (start, end, step) => range(start, end + 1, step)
const LEVELS_PROGRESS = genRange(VOTING_PROGRESS_STEPS_PROMPT, VOTING_PROGRESS_MAX_PROMPTS, VOTING_PROGRESS_STEPS_PROMPT)
const LEVELS_IDEA = genRange(VOTING_PROGRESS_STEPS_IDEAS, VOTING_PROGRESS_MAX_IDEAS, VOTING_PROGRESS_STEPS_IDEAS)
const LEVELS_MOTIVATION = genRange(15, VOTING_PROGRESS_MAX_PROMPTS, VOTING_PROGRESS_STEPS_PROMPT)

const GradientBar = ({progress, inPopup = false}) => (
  <div className={clsx('h-full rounded-full max-w-b4-max', {
    'w-1.5 pt-3': !inPopup,
    'w-4 pt-4': inPopup
  
  })} style={{
      backgroundImage: 'linear-gradient(to top, #9B28F6, #EE0AC9, #E4DD34, #49FFA8)',
    }}>
      <div className="h-full relative">
        { isNumber(progress) && 
          <div className={clsx('rounded-full absolute outline', {
            'w-3 h-3 outline-4 -left-1/2 outline-b4-bg-dark bg-white' : !inPopup,
            'w-4 h-4 outline-4 outline-white bg-b4-bg-dark': inPopup,
          })} style={{
            bottom: `${Math.round(progress * 100)}%`,
            transition: 'bottom 0.5s ease-in-out'
          }} /> 
        }
      </div>
  </div>
)

const VotingProcesModalLabels = ({label, range}) => (
  <div className="flex flex-col pt-4">
    <div className="flex flex-col-reverse justify-between grow">
      {range.map((step, i) => (
        <div key={i} className="grow">
          <div className="h-4 flex flex-col justify-center -translate-y-full">
            <B4Text className="text-right">{step}{i === 0 && '+'} {label}</B4Text>
          </div>
        </div>
      ))}
    </div>
  </div>
)

enum VotingProgressModalState {
  CLOSED,
  OPEN,
  OPEN_TIMEOUT
}

const VotingProgressModal = ({status, onClose, progressPrompts, progressPromptsLevel, conversationId}) => {
  const { t } = useTranslation()

  const { data: {resonance: {answers}} = {resonance: {}}, loading: loadingResonance } = useResonance({
    variables: { conversation_id: conversationId, conversationStats: false },
    fetchPolicy: 'cache-and-network',
    skip: status === VotingProgressModalState.CLOSED
  });

  const progressIdeas = isNumber(answers?.length) ? Math.min(1, answers.length / VOTING_PROGRESS_MAX_IDEAS) : null

  return (
    <B4ModalConfirm open={status !== VotingProgressModalState.CLOSED} onOk={onClose} onCancel={onClose} lblOk={t('lblLetsContinue')} timeout={status === VotingProgressModalState.OPEN_TIMEOUT}>
      <B4SpaceVertical>
        <B4TextBig className="text-center" html={t('txtReachedLevel', {level: progressPromptsLevel})} />
        <div className="flex justify-center h-64 space-x-b4-std-2x">
          {!loadingResonance && <>
            <B4SpaceHorizontal>
              <GradientBar progress={progressPrompts} inPopup />
              <VotingProcesModalLabels label={t('txtBattles')} range={LEVELS_PROGRESS} />
            </B4SpaceHorizontal>
            <B4SpaceHorizontal>
              <GradientBar progress={progressIdeas} inPopup />
              <VotingProcesModalLabels label={t('txtIdeas')} range={LEVELS_IDEA} />
            </B4SpaceHorizontal>
          </>}
        </div>
        <B4Text className="text-center">{t('txtHavingFun')}</B4Text>
      </B4SpaceVertical>
    </B4ModalConfirm>
  )
}

const ProgressLogo = ({progress}) => {
  const upperBound = Math.min((1-progress) * 100 + 10, 100)
  const lowerBound = Math.max((1-progress) * 100 - 10, 0)
  return (
    <svg viewBox="0 0 114 60">
      <g transform="matrix(1,0,0,1,-18,-15)">
          <path d="M33.9,73.665C33.9,73.665 19.985,73.87 19.711,62.379C19.382,48.537 31.939,48.824 31.939,48.824C31.936,48.757 31.891,47.653 31.888,47.594C31.881,47.436 31.875,47.302 31.875,47.161C31.875,39.992 35.026,33.382 40.141,29.604C45.235,25.841 51.59,25.384 57.025,28.366L57.923,28.858L58.394,27.949C61.941,21.099 68.251,17 74.973,17C81.695,17 88.006,21.099 91.552,27.949L92.023,28.858L92.921,28.366C98.351,25.389 104.707,25.846 109.796,29.604C114.911,33.376 118.062,39.986 118.067,47.151L118.067,47.316C118.065,47.351 118.063,47.389 118.061,47.431L118.061,47.44C118.055,47.544 118.049,47.677 118.049,47.813L118.049,48.647L118.869,48.797C124.54,49.833 130.444,53.191 130.545,61.551C130.691,73.684 118.986,73.655 118.986,73.655L33.9,73.665Z" style={{fill: 'url(#myGradient)'}} />
          <path d="M33.9,73.665C33.9,73.665 19.985,73.87 19.711,62.379C19.382,48.537 31.939,48.824 31.939,48.824C31.936,48.757 31.891,47.653 31.888,47.594C31.881,47.436 31.875,47.302 31.875,47.161C31.875,39.992 35.026,33.382 40.141,29.604C45.235,25.841 51.59,25.384 57.025,28.366L57.923,28.858L58.394,27.949C61.941,21.099 68.251,17 74.973,17C81.695,17 88.006,21.099 91.552,27.949L92.023,28.858L92.921,28.366C98.351,25.389 104.707,25.846 109.796,29.604L109.796,29.604C114.911,33.376 118.062,39.986 118.067,47.151L118.067,47.316C118.065,47.351 118.063,47.389 118.061,47.431L118.061,47.44C118.055,47.544 118.049,47.677 118.049,47.813L118.049,48.647L118.869,48.797C124.54,49.833 130.444,53.191 130.545,61.551C130.691,73.684 118.986,73.655 118.986,73.655L33.9,73.665Z" style={{fill: 'none', fillRule: 'nonzero', stroke: '#49FFA8', strokeWidth: '2px' }}/>
      </g>
      <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
              <stop offset={`${lowerBound}%`} stopColor="#FFFFFF" />
              <stop offset={`${upperBound}%`} stopColor="#49FFA8" />
              <stop offset="100%" stopColor="#49FFA8" />
          </linearGradient>
      </defs>
    </svg>
  )
}

const VotingMotivationModal = ({status, onClose, motivationLevelIndex, progressPrompts}) => {
  const { t } = useTranslation()
  const battles = LEVELS_MOTIVATION[motivationLevelIndex]

  return (
    <B4ModalConfirm
      open={status !== VotingProgressModalState.CLOSED}
      timeout={status === VotingProgressModalState.OPEN_TIMEOUT}
      onOk={onClose}
      onCancel={onClose}
      lblOk={t('lblLetsContinue')}
    >
      <B4SpaceVertical>
        <B4TextBig className="text-center" html={`${t('txtMotivationLevels', { returnObjects: true })[motivationLevelIndex]}<br/>${t('txtMotivation', {battles})}`}/>
        
        
        <div className="flex justify-center h-16 relative">
          <ProgressLogo progress={progressPrompts} />
          <div className="absolute inset-0 flex justify-center items-center">
            <GiPartyPopper className="text-white text-5xl" />
          </div>
        </div>
        <B4Text className="text-center">{t('txtHavingFun')}</B4Text>
      </B4SpaceVertical>
    </B4ModalConfirm>
  )
}

export const genVotingProgressLevel = (noPrompts) => isNumber(noPrompts) ? Math.floor(Math.min(noPrompts, VOTING_PROGRESS_MAX_PROMPTS) / VOTING_PROGRESS_STEPS_PROMPT) : null

export const VotingProgress = ({noPrompts, conversationId}) => {
  const [progressModalOpen, setProgressModalOpen] = useState(VotingProgressModalState.CLOSED)
  const [motivationModalOpen, setMotivationModalOpen] = useState(VotingProgressModalState.CLOSED)

  const progressPrompts = isNumber(noPrompts) ? Math.min(1, noPrompts / VOTING_PROGRESS_MAX_PROMPTS) : null
  const progressPromptsLevel = genVotingProgressLevel(noPrompts)

  const promptsLevelExactIndex = LEVELS_PROGRESS.indexOf(noPrompts)
  const motivationLevelExactIndex = LEVELS_MOTIVATION.indexOf(noPrompts)

  useChangedEffect(() => {
    if (promptsLevelExactIndex >= 0) {
      setProgressModalOpen(VotingProgressModalState.OPEN_TIMEOUT)
    }
  }, [promptsLevelExactIndex])

  useChangedEffect(() => {
    if (motivationLevelExactIndex >= 0) {
      setMotivationModalOpen(VotingProgressModalState.OPEN_TIMEOUT)
    }
  }, [motivationLevelExactIndex])
  
  
  return (<>
    <div className="fixed right-0 left-0 top-1/3 bottom-24 pr-b4-std-half flex justify-center pointer-events-none">
      <div className="max-w-full w-b4-max flex justify-end">
        <div className="px-2 -mx-2 pointer-events-auto cursor-pointer" onClick={() => setProgressModalOpen(VotingProgressModalState.OPEN)}>
          <GradientBar progress={progressPrompts} />
        </div>
      </div>
    </div>
    <VotingProgressModal status={progressModalOpen} onClose={() => setProgressModalOpen(VotingProgressModalState.CLOSED)} progressPrompts={progressPrompts} progressPromptsLevel={progressPromptsLevel} conversationId={conversationId} />
    <VotingMotivationModal status={motivationModalOpen} onClose={() => setMotivationModalOpen(VotingProgressModalState.CLOSED)} motivationLevelIndex={motivationLevelExactIndex} progressPrompts={progressPrompts} />
  </>)
}