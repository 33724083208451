import React from 'react';
import { B4Bubble } from '../../design/atomic/bubble';
import { B4Text } from '../../design/atomic/text';

const QuestionCard = props => {
  const {
    question,
  } = props;


  return (
    <B4Bubble><B4Text html={question}/></B4Bubble>
  );
};

export default QuestionCard;
