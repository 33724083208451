import { Modal } from "@mui/material";

const CustomizedModal = ({ open, onClose, children}) => (
  <Modal
    open={open}
    slotProps = {{
      backdrop: {
        className: 'z-40'
      }
    }}
    onClose={onClose}
    disableEnforceFocus
    disableAutoFocus
  >
    {children}
  </Modal>
)

export default CustomizedModal;