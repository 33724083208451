import {gql, useQuery} from '@apollo/client';

export const CONVERSATION_QUERY = gql`
 query ($conversation_id: ID!) {
    conversation(id: $conversation_id) {
      id
      question
      answer_count
      non_seed_answers
      start_at
      end_at
      user {
        id
        gender
        country
        email
        town {
          id
          code
          name
        }
        age
        name
        avatar
      }
      created_at
    }
  }
`;

const useConversation = (options = {}) => useQuery(CONVERSATION_QUERY, options);

export default useConversation;
