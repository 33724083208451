import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { ALL_ANSWERS_QUERY } from '../queries/useAnswers';
import { DELETED_ANSWERS_QUERY } from '../queries/useDeletedAnswers';
import { APPROVAL_ANSWER_QUERY } from '../queries/useApprovalAnswers';

const ANSWER_RESTORE = gql`
  mutation($id: ID!) {
    restoreAnswer(id: $id) {
      id
    }
  }
`;

const useRestoreAnswer = ({ conversationId }) => {
  return useMutation(ANSWER_RESTORE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ALL_ANSWERS_QUERY,
        variables: { conversation_id: conversationId },
      },
      {
        query: DELETED_ANSWERS_QUERY,
        variables: { conversation_id: conversationId },
      },
      {
        query: APPROVAL_ANSWER_QUERY,
        variables: { conversation_id: conversationId },
      }
    ]
  });
};

export default useRestoreAnswer;
