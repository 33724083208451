import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import React from 'react';

export const Loading = ({ show }) => {
  return (
    <Backdrop className='bg-white opacity-90 z-30' open={show}>
      <CircularProgress className='text-primary' />
    </Backdrop>
  );
};
