
import { useMe, useTenant } from '../../hooks';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { useState } from 'react';
import clsx from 'clsx';
import { MenuModal, MenuItem } from '../ui/context-menu';

const Logo = ({ className = '', ...props }) => {
  const tenant = useTenant()
  const navigate = useNavigate()

  const { data } = useMe()

  const [ open, setOpen ] = useState(false)
  const { tenants } = data?.me || {}

  const genRoomLogo = tenant => (<div className='flex flex-col items-center space-y-2 w-full'>{tenant.image ? <img alt="" className='h-9' src={tenant.image} /> : <div className='font-semibold text-primary'>{tenant.about_heading}</div>}</div>)
  const hasMultipleTenants = tenants?.length > 1

  return (<>
    {tenants && <MenuModal title={t('lblSelectRoom')} open={open} onClose={() => setOpen(false)}>{
      tenants.map((tenant, i) => <MenuItem key={i} onClick={() => navigate(`/room/${tenant.id}`)}>{tenant.about_heading}</MenuItem>)
    }
    </MenuModal>}
    <div className={clsx(className, 'py-2 space-y-2 cursor-pointer')} {...props} onClick={hasMultipleTenants ? (() => setOpen(true)) : null}>
      { tenant && genRoomLogo(tenant) }
      { tenant && hasMultipleTenants && <div className='pb-2 flex justify-center items-center '>{
          tenants.map((cur, i) => (
            <div key={i} style={{padding: '0 0.1875rem'}}>
              <div className={clsx(
                'rounded-full', 
                tenant?.id === cur.id ? 'w-2 h-2 bg-primary' : 'w-1.5 h-1.5 bg-light')} />
            </div>
          ))
        }
        </div>
      }
    </div>
  </>);
};

export default Logo;
